import { Controller } from "stimulus";
export default class extends Controller {
  // option -> ??
  // button -> element to show/hide
  // unhide -> element to remove 'hidden' class from
  // optionsToFilter -> element to filter
  static targets = ["option", "button", "unhide", "optionsToFilter"];
  
  connect() {
    this.optionTargets.forEach((option) => {
      if (option.checked === true) {
        let targetElement = this.buttonTargets.find(
          (el) => el.id === option.value
        );
        targetElement.classList = ["block"];
      }
    });
  }

  // This method adds/removes hidden class to elements with id matching this targets value
  selectRadioOption(e) {
    // To allow for multiple radio groups on the same page, we need to filter the buttonTargets by the identifierName
    this.buttonTargets.forEach((targetElement) => {
      // If no identifierName is set, the condition will always be true (to allow for older implementations)
      const parentIdentifierName = e.target.dataset['parentIdentifierName'];
      const childIdentifierName = targetElement.dataset['childIdentifierName'];
      if (parentIdentifierName !== childIdentifierName) return; // Skip if the child does not belong to the parent

      const shouldShowElement = e.target.value === targetElement.id;
      if (shouldShowElement) {
        targetElement.classList.remove("hidden");

        const fieldsToEnable = document.getElementsByClassName(e.target.dataset.enablefield)
        this.toggleElementsDisableState(fieldsToEnable, true)
      } else {
        targetElement.classList.add("hidden");

        let fieldsToDisable = document.getElementsByClassName(e.target.dataset.disablefield)
        this.toggleElementsDisableState(fieldsToDisable, false)
      }
    });
  }

  //**
  //  * Toggles the disabled state for a collection of elements.
  //  *
  //  * @param {HTMLCollection} elements - The collection of elements to toggle the disabled state for.
  //  * @param {boolean} disabled - If true, the elements will be enabled; if false, the elements will be disabled.
  //  */
  toggleElementsDisableState(elements, disabled) {
    Array.from(elements).forEach(element => {
        if (disabled) {
            element.removeAttribute('disabled');
        } else {
            element.setAttribute('disabled', 'true');
        }
    });
  }
  
  // Hide all options that don't match the selected filter
  filterOptions(e) {
    this.optionsToFilterTargets.forEach((targetElement) => {
      targetElement.parentNode.classList.remove("hidden");
      let isFirstElement = true;

      // convert HTMLCollection to array
      Array.from(targetElement.options).forEach((option) => {
        if (e.target.value === option.dataset['category']) {
          option.classList.remove("hidden");

          // Set first valid option as selected
          if (isFirstElement) {
            targetElement.selectedIndex = option.index;
            isFirstElement = false;
          }
        } else {
          option.classList.add("hidden");
        }
      })
    });
  }

  unhideOptions() {
    this.unhideTarget.classList.remove("hidden");
  }
}
