import { Controller } from "stimulus";
import {validateForm, validateField, initialiseFormValidation} from "../packs/utils/form_validations";

export default class extends Controller {
    static targets = ["form"];

    connect() {
        this.formTarget.addEventListener("submit", this.validate.bind(this));
        initialiseFormValidation(this.formTarget);
    }

    validate(event) {
        if (!validateForm(this.formTarget)) {
            event.preventDefault();
        }
    }

    validateField(event) {
        validateField(event.target);
    }
}