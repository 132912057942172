import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["category"];
  
  // This method is specifically for trainings form
  selectCategory(e) {
    this.categoryTarget.value = e.target.dataset['category'];
  }
}

