import validator from "validator";

/**
 * Default validation messages for different validation types.
 * @type {Object}
 */
const defaultMessages = {
    presence: "Required",
    email: "Invalid email address"
};

/**
 * Gets all fields in a form.
 * @param {HTMLFormElement} form - The form element to get fields from.
 * @returns {NodeListOf<HTMLElement>} - Returns all fields in the form.
 */
const getAllFields = (form) => {
    return form.querySelectorAll("input, select, textarea");
};

/**
 * Initialises form validation logic by setting up event listeners for all fields within a form.
 *
 * This function retrieves all the fields in the given form and attaches a "blur" event listener
 * to each field. The event listener triggers the field validation process when the user
 * navigates away (blurs) from the respective field.
 *
 * @param {HTMLFormElement} form - The form element containing fields to be validated.
 */
export const initialiseFormValidation = (form) => {
    // Set the form to hide the default HTML validation messages
    form.setAttribute("novalidate", true.toString());

    const fields = getAllFields(form);
    fields.forEach((field) => {
        field.addEventListener("blur", () => validateField(field));
    });
};

/**
 * Validates all fields in a form.
 * @param {HTMLFormElement} form - The form element to validate.
 * @returns {boolean} - Returns true if all fields are valid, otherwise false.
 */
export const validateForm = (form) => {
    const fields = getAllFields(form);
    for (let field of fields) {
        if (!validateField(field)) return false;
    }
    return true;
};

/**
 * Validates a single field.
 * @param {HTMLElement} field - The field element to validate.
 * @returns {boolean} - Returns true if the field is valid, otherwise false.
 */
export const validateField = (field) => {
    removeErrorFromField(field);
    if (field.disabled || field.readOnly) return true;

    const isValidRequired = validateRequired(field);
    if (!isValidRequired) return false;

    const isValidEmail = validateEmail(field);
    if (!isValidEmail) return false;

    return true;
};

/**
 * Validates if a field is required and not empty.
 * @param {HTMLElement} field - The field element to validate.
 * @returns {boolean} - Returns true if the field is valid, otherwise false.
 */
const validateRequired = (field) => {
    if (field.hasAttribute("required")) {
        const requiredMessage = field.getAttribute("data-error-required-message") || defaultMessages.presence;
        if (validator.isEmpty(field.value)) {
            applyErrorToField(field, requiredMessage);
            return false;
        }
    }
    return true;
};

/**
 * Validates if a field contains a valid email address.
 * @param {HTMLElement} field - The field element to validate.
 * @returns {boolean} - Returns true if the field is valid, otherwise false.
 */
const validateEmail = (field) => {
    if (field.type === "email") {
        const emailMessage = field.getAttribute("data-error-email-message") || defaultMessages.email;
        if (!validator.isEmail(field.value)) {
            applyErrorToField(field, emailMessage);
            return false;
        }
    }
    return true;
};

/**
 * Applies an error message to a field.
 * @param {HTMLElement} field - The field element to apply the error to.
 * @param {string} message - The error message to display.
 */
export const applyErrorToField = (field, message) => {
    const error = document.createElement("div");
    error.textContent = message;
    error.classList.add("text-sm", "text-red-500", "error");
    field.parentNode.appendChild(error);
    field.style.setProperty("border-color", "red", "important");
};

/**
 * Removes the error message from a field.
 * @param {HTMLElement} field - The field element to remove the error from.
 */
export const removeErrorFromField = (field) => {
    const error = field.parentNode.querySelector(".error");
    if (error) {
        error.remove();
        field.style.removeProperty("border-color");
    }
};